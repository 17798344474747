<template>
    <div>
        <breadcrumbs v-if="$route.path !== '/'" />

        <div class="container content-space-t-1 content-space-b-2">
            <div class="row gy-5">
                <div class="col-12 col-md-7">
                    <h1 class="display-4 text-primary">
                        {{ $prismic.asText(slice.primary.h1) }}
                    </h1>
                    <div 
                        class="lead fs-4 rich-text mb-4"
                        v-html="$prismic.asHtml(slice.primary.teaser)" 
                    />
                    <div class="d-flex flex-column gap-3">
                        <dropdown 
                            v-if="topList && topList.length" 
                            :title="slice.primary.overwrite_toplist_translation || $translate('toplist', 'Topplista')" 
                        > 
                            <ol class="list-unstyled ordered-list mb-0">
                                <li 
                                    v-for="(topListItem, index) in topList" 
                                    :key="index"
                                >
                                    <nuxt-link :to="$prismic.linkResolver(topListItem)" class="text-dark"> 
                                        {{ topListItem.data.title }}
                                    </nuxt-link>
                                </li>
                            </ol>
                            <a 
                                v-scroll-to="{el: `#${$formatId(listSlice.id)}`, offset: -100}" 
                                :href="`#${$formatId(listSlice.id)}`"
                                class="fw-bold mt-3 d-block"
                            >
                                {{ $translate('show_all', 'Visa alla') }}
                            </a>
                        </dropdown>

                        <dropdown 
                            v-for="(item, index) in dropdownContent"
                            :key="`dropdown${index}`"
                            :title="item.dropdown_header" 
                        > 
                            <div class="rich-text" v-html="$prismic.asHtml(item.dropdown_content)" />
                        </dropdown>

                        <dropdown 
                            v-if="slice.primary.include_table_of_contents" 
                            :title="$translate('table_of_contents', 'Sidans innehåll')" 
                        > 
                            <table-of-contents-list :slices="slices" />
                        </dropdown>
                    </div>
                </div>
                <div v-if="itemType" class="col-12 col-md-5">
                    <featured-item
                        :header="slice.primary.featured_item_h2"
                        :featured-item="slice.primary.featured_item"
                        :text="slice.primary.featured_item_p"
                        :type="itemType"
                        :include-loan-guide-link="slice.primary.include_loan_guide_link"
                        :alternate-data-config="alternateDataConfig"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FeaturedItem from '@/components/BannerFeaturedItem.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Dropdown from '@/components/BannerDropdown.vue';
import TableOfContentsList from '@/components/TableOfContentsList.vue';
import TopListLogic from '@/mixins/TopListLogic.js';

export default {
    components: {
        FeaturedItem,
        Breadcrumbs,
        Dropdown,
        TableOfContentsList
    },
    mixins: [TopListLogic],
    props: {
        slice: {
            type: Object,
            required: true
        },
        slices: {
            type: Array,
            required: true
        }
    },
    computed: {
        dropdownContent() {
            return this.slice.items
                .filter(item => item.dropdown_header);
        },
        topList() {
            if (! this.slice.primary.top_list_amount || ! this.listSliceItems) {
                return null;
            }
            const topListWithData = this.listSliceItems.map(item => {
                if (!this.itemType) {
                    return null;
                }
                if (! item.data) {
                    return this.$store.state[this.itemType.storeKey][item.id];
                }
                return item;
            });
            return topListWithData
                .filter(item => item)
                .slice(0, (this.slice.primary.top_list_amount));
        },
        itemType() {
            if (this.slice.primary.featured_item.type === 'lender') {
                return { storeKey: 'lenders', modelKey: 'Loan', imgKey: 'logo'};
            }
            if (this.slice.primary.featured_item.type === 'lender_corporate') {
                return { storeKey: 'corporateLenders', modelKey: 'CorporateLoan', imgKey: 'logo'};
            }
            if (this.slice.primary.featured_item.type === 'credit_card') {
                return { storeKey: 'cards', modelKey: 'CreditCard', imgKey: 'card_image'};
            }
            return null;
        },
        alternateDataConfig() {
            return this.$getAlternateDataConfig(this.slice);
        }
    },
};
</script>