<template>
    <div class="card-footer bg-white fs-6 fst-italic p-3">
        <div v-if="isExpandable" role="button" @click="expanded = true">
            {{ truncatedComplianceExample }}
            <a class="collapsed">Ver más</a>
        </div>
        <div v-else>
            {{ complianceExample }}
        </div>
        <div v-if="($isSweden() && isHighCostCredit) || ($isSpain() && warningInfo)" class="row mt-3 pt-3 border-top g-0">
            <div class="col me-2" style="max-width: 50px">
                <svg id="Layer_1" role="img" aria-label="Observera!" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1200 1200" style="enable-background:new 0 0 1200 1200;" xml:space="preserve" class="injected-svg svg-inject">
                    <g>
                        <path class="st0" d="M1176.1,1022.2L652.2,114.7c-10.8-18.7-30.8-30.2-52.4-30.2s-41.6,11.5-52.4,30.2L23.5,1022.2 c-10.8,18.7-10.8,41.8,0,60.5c10.8,18.7,30.8,30.2,52.4,30.2h1047.9c21.6,0,41.6-11.5,52.4-30.2 C1186.9,1063.9,1186.9,1040.9,1176.1,1022.2z M180.7,991.9l419.1-726l419.1,726H180.7z" />
                        <path class="st0" d="M642.7,836.1c-11.5-10.2-25.7-15.3-42.9-15.3c-17.1,0-31.4,5.1-42.9,15.3c-11.5,10.2-17.2,23.2-17.2,39 c0,15.8,5.7,28.8,17.2,39c11.4,10.2,25.7,15.3,42.9,15.3c17.1,0,31.4-5.1,42.9-15.3c11.4-10.2,17.2-23.2,17.2-39 C659.8,859.2,654.1,846.2,642.7,836.1z" />
                        <polygon class="st0" points="556.8,775.6 644.4,775.6 658.2,456.1 543,456.1 " />
                    </g>
                </svg>
            </div>
            <div class="col">
                <p v-if="$isSweden()" class="text-left">
                    <strong>{{ $translate('card_high_cost_credit_header', 'Det här är en högkostnadskredit') }}</strong>
                    <br>{{ $translate('card_high_cost_credit_info', 'Om du inte kan betala tillbaka hela skulden riskerar du en betalningsanmärkning. För stöd, vänd dig till budget- och skuldrådgivningen i din kommun. Kontaktuppgifter finns på ') }}
                    <a 
                        href="https://www.hallakonsument.se" 
                        rel="nofollow"
                    >
                        {{ $translate('card_high_cost_credit_link', 'hallåkonsument.se') }}
                    </a>
                </p>
                <div 
                    v-else-if="$isSpain()" 
                    class="rich-text" 
                    v-html="$prismic.asHtml(warningInfo)"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        complianceExample: {
            type: String,
            required: true
        },
        isHighCostCredit: {
            type: Boolean,
            required: true
        },
        lender: {
            type: Object,
            required: false,
            default: null
        }
    },
    data(){
        return {
            expanded: false
        };
    },
    computed: {
        truncatedComplianceExample() {
            return this.complianceExample.slice(0, 125) + '...';
        },
        isExpandable() {
            return this.$isSpain() && this.complianceExample?.length > 150 && !this.expanded;
        },
        warningInfo() {
            return this.lender && this.lender.data.warning_info[0]?.text?.length > 0 ? 
                this.lender.data.warning_info : 
                undefined;
        }
    }
};
</script>